import React, { useState, useContext, createContext, useEffect } from "react"

import { AppContext } from "src/gatsby-theme-wild-child/context/ContextProvider"
import { useRealmApp } from "src/gatsby-theme-wild-child/context/RealmApp"
import { useCollection } from "../../hooks/useCollection"
import { ref, onValue, off } from "firebase/database"
import database from "./firebase"
import { v4 as uuidv4 } from "uuid"
import { Text, useBreakpointValue } from "@chakra-ui/react"
import axios from "axios"
import DisclaimerBusinessSetup from "../DisclaimerBusinessSetup"
import DisclaimerCorporateTax from "../DisclaimerCorporateTax"

const ChatContext = createContext<ChatValues>({} as ChatValues)

export function ChatProvider({ children }) {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { selectedPrompt, setSelectedPrompt } = useContext(AppContext)
  const namespaceKey = process.env.GATSBY_ACTIVE_PROMPT || "BusinessSetup"
  const [scrollBottom, setScrollBottom] = useState(false)

  let retryCount = 0
  const maxRetries = 3

  const { currentUser } = useRealmApp() as any
  const feedbackCollection = useCollection({
    db: "vzchat",
    collection: "feedback",
  })

  const corporateTaxTitle = () => {
    return (
      <>
        <Text
          as="span"
          fontSize={["2xl", "3xl", "48px", "48px", "40px", "50px"]}
        >
          Got questions about UAE’s Corporate Tax?
        </Text>
        <br />
        <Text
          as="span"
          fontSize={["2xl", "5xl", "90px", "90px", "80px", "90px"]}
        >
          Just ask
        </Text>
        <Text
          as="span"
          color="red.full"
          fontSize={["4xl", "5xl", "90px", "90px", "80px", "90px"]}
        >
          {" "}
          TaxGPT
        </Text>{" "}
      </>
    )
  }

  const corporateTaxDescription = () => {
    return (
      <>
        <Text mt={isMobile ? "5px" : ""} lineHeight={["1.0", "1.0", "0.5"]}>
          TaxGPT by Virtuzone, is the UAE’s first (and smartest) AI-powered
          Corporate tax assistant.
        </Text>
      </>
    )
  }

  const businessSetupTitle = () => {
    return (
      <>
        Say hi to{" "}
        <Text as="span" color="red.full">
          {" "}
          ChatVZ <br />{" "}
        </Text>{" "}
        The world’s first <br /> Business Setup AI.
      </>
    )
  }

  const actions = {
    BusinessSetup: {
      key: "BusinessSetup",
      title: businessSetupTitle(),
      promptTitle: "Ask ChatVz a question...",
      gTagEvent: "QuestionChatVZ",
      chatIcon: {
        show: true,
        label: "V",
        title: "ChatVZ",
      },
      metaTitle: "ChatVZ by Virtuzone",
      description:
        "What do you get when you cross Dubai’s leading business setup experts with the most powerful AI on the planet? ChatVZ. It will answer all of your questions about business setup in the UAE.",
      action: "Some example questions you can ask ChatVZ",
      types: [
        {
          key: "How do I start the visa process?",
          target: null,
          label: "How do I start the visa process?",
        },
        {
          key: "How do I know whether to set up a Free Zone or Mainland company?",
          target: null,
          label: "Freezone or mainland?",
        },
        {
          key: "Tell me more about corporate tax in 2023. Will it affect my company?",
          target: null,
          label: "Will corporate tax affect me?",
        },
        {
          key: "How can I set up a bank account? Can you help with that?",
          target: null,
          label: "How do I get a bank account?",
        },
      ],
      desclaimer: {
        short:
          'ChatVZ provides general information from online sources including government websites, free zone websites, and Virtuzone\'s website. It is not personalized advice. Information can change and may not apply to your specific circumstances. For specific advice, consult a professional or the relevant website. View the complete disclaimer <a href="/disclaimer">here</a>',
        mobile:
          'Information provided by ChatVZ is for general guidance only. See our full <a target="_blank" href="/disclaimer">disclaimer</a>',
        long: DisclaimerBusinessSetup,
      },
      meta: {
        title:
          "ChatVZ by Virtuzone | World's First AI-Powered UAE Business Setup Assistant",
      },
      transcriptConfig: {
        label: "Success",
        subLabel: "Your ChatVZ chat transcript has been sent to you.",
        gTagEvent: "SaveAnswerChatVZ",
      },
      consultationConfig: {
        gTagEvent: "ConsultationChatVZ",
      },
    },
    CorporateTax: {
      key: "CorporateTax",
      title: corporateTaxTitle(),
      promptTitle: "Ask TaxGPT a question",
      gTagEvent: "QuestionAskTaxGPT",
      chatIcon: {
        show: true,
        label: "T",
        title: "TaxGPT",
      },
      metaTitle: "TaxGPT by Virtuzone",
      description: corporateTaxDescription(),
      action: "Start with one of our top prompts",
      types: [
        {
          key: "What do free zone companies have to do for corporate tax?",
          target: null,
          label: "Tax info for free zone companies",
        },
        {
          key: "What do mainland companies have to do for corporate tax?",
          target: null,
          label: "Mainland company corporate tax",
        },
        {
          key: "What do freelancers have to do for corporate tax?",
          target: null,
          label: "Freelancer corporate tax",
        },
        {
          key: "I would like to talk to a tax expert about my corporate tax requirements",
          target: {
            type: "_blank",
            link: "https://calendly.com/virtuzone-cs/corporate-tax-consultation?utm_source=TaxGPT&utm_medium=Website&utm_campaign=CorporateTaxCalendly",
          },
          label: "Book Free Consultation",
        },
      ],
      desclaimer: {
        short:
          'TaxGPT is trained to offer general tax information from online publications released by the Ministry of Finance and the Federal Tax Authority. It is not personalized advice. Tax laws change and vary according to your specific circumstances. For specific advice, consult a tax professional or government website. Visit our <a target="_blank" href="/disclaimer">Disclaimer</a> Link for sources.',
        mobile:
          'Information provided by TaxGPT is for general guidance only. See our full <a target="_blank" href="/disclaimer">disclaimer</a>',
        long: DisclaimerCorporateTax,
      },
      meta: {
        title:
          "TaxGPT by Virtuzone | World's First AI-Powered UAE Corporate Tax Assistant",
      },
      transcriptConfig: {
        label: "Success",
        subLabel: "Your TaxGPT chat transcript has been sent to you.",
        gTagEvent: "SaveAnswerTaxGPT",
      },
      consultationConfig: {
        gTagEvent: "ConsultationTaxGPT",
      },
    },
    TaxBotTesting: {
      key: "TaxBotTesting",
      title: corporateTaxTitle(),
      promptTitle: "Ask TaxGPT a question",
      gTagEvent: "QuestionAskTaxGPT",
      chatIcon: {
        show: true,
        label: "T",
        title: "TaxGPT",
      },
      metaTitle: "TaxGPT by Virtuzone",
      description: corporateTaxDescription(),
      action: "Start with one of our top prompts",
      types: [
        {
          key: "What do free zone companies have to do for corporate tax?",
          target: null,
          label: "Tax info for free zone companies",
        },
        {
          key: "What do mainland companies have to do for corporate tax?",
          target: null,
          label: "Mainland company corporate tax",
        },
        {
          key: "What do freelancers have to do for corporate tax?",
          target: null,
          label: "Freelancer corporate tax",
        },
        {
          key: "I would like to talk to a tax expert about my corporate tax requirements",
          target: {
            type: "_blank",
            link: "https://calendly.com/virtuzone-cs/corporate-tax-consultation?utm_source=TaxGPT&utm_medium=Website&utm_campaign=CorporateTaxCalendly",
          },
          label: "Book Free Consultation",
        },
      ],
      desclaimer: {
        short:
          'TaxGPT is trained to offer general tax information from online publications released by the Ministry of Finance and the Federal Tax Authority. It is not personalized advice. Tax laws change and vary according to your specific circumstances. For specific advice, consult a tax professional or government website. Visit our <a target="_blank" href="/disclaimer">Disclaimer</a> Link for sources.',
        mobile:
          'Information provided by TaxGPT is for general guidance only. See our full <a target="_blank" href="/disclaimer">disclaimer</a>',
        long: DisclaimerCorporateTax,
      },
      meta: {
        title:
          "TaxGPT by Virtuzone | World's First AI-Powered UAE Corporate Tax Assistant",
      },
      transcriptConfig: {
        label: "Success",
        subLabel: "Your TaxGPT chat transcript has been sent to you.",
        gTagEvent: "SaveAnswerTaxGPT",
      },
      consultationConfig: {
        gTagEvent: "ConsultationTaxGPT",
      },
    },
  }

  /*************************************************/
  /* STATE                                         */
  /*************************************************/
  const [namespace, setNamespace] = useState(actions[namespaceKey])
  const [transcriptHistory, setTranscriptHistory] = useState<any[]>([])
  const [query, setQuery] = useState<string>("")
  const [isListening, setIsListening] = useState<boolean>(false)
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [focused, setFocused] = useState<boolean>(false)
  const [metaData, setMetaData] = useState<any>([])
  const [gpt4, setGpt4] = useState<boolean>(true)
  const [references, setReferences] = useState<any[]>([])
  const [positiveFeedback, setPositiveFeedback] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)
  const [sessionId, setSessionId] = useState<string>(uuidv4())
  const [feedbackContent, setFeedbackContent] = useState({
    response: "",
    reasons: [],
    message: "",
  }) as any

  /*************************************************/
  /* USER SUBMISSIONS                              */
  /*************************************************/

  async function handleSubmit(overrideQuery = null) {
    const searchParams = new URLSearchParams(document.location.search)
    const isGpt4 = searchParams.get("gpt4")
    if (isGpt4 !== null) {
      setGpt4(isGpt4 === "true")
    }

    if (typeof window !== "undefined") {
      ;(window as any).dataLayer.push({
        event: namespace?.gTagEvent,
      })
    }

    const questionToUse = overrideQuery || query
    const transcripts = transcriptHistory
      .filter(t => t.role !== "error")
      .map(tr => {
        return {
          content: tr.content,
          role: tr.role,
        }
      })

    const newTranscriptHistory = [
      ...transcripts,
      { role: "user", content: questionToUse },
    ]

    setTranscriptHistory(newTranscriptHistory)

    // Show Chatbot ... animation
    setIsListening(true)

    setQuery("")

    // Look if selectedPrompt is empty or null or undefinded
    // then call to get preselected namespaces based active prompt and set it to state.
    if (
      !selectedPrompt ||
      selectedPrompt === undefined ||
      selectedPrompt === null
    ) {
      await fetchPromptWithRetry()
    }

    const fetchOptions = {
      method: "POST",
      body: JSON.stringify({
        transcript: newTranscriptHistory,
        useReferences: true,
        sessionId: sessionId,
        prompt: selectedPrompt.prompt,
        namespace: selectedPrompt.namespace,
        settings: {
          model: gpt4 ? "gpt-4-1106-preview" : "gpt-3.5-turbo",
          max_tokens: 450,
          temperature: 0.7,
          stream: true,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 80000,
    }

    fetch(`${process.env.GATSBY_API_BASE_URL}/chatstream`, fetchOptions)
      .then(res => res.json())
      .then(data => {
        setMetaData(data.response || [])
        setReferences(data.response?.references || [])
        setTimeout(() => {
          setIsTyping(false)
        }, 300)
      })
      .catch(err => {
        if (err instanceof Error) {
          console.log("Network request failed:", err.message)
        } else if (err.response) {
          const statusCode = err.response.status
          console.log("Status code:", statusCode)

          setTranscriptHistory([
            ...newTranscriptHistory,
            {
              role: "error",
              content:
                "Hey it looks like we're getting a lot of traffic and it's slowing me down a bit. You can try typing your question again or reach out to one of the humans I work with on +971 4 457 8200 or send a message.",
            },
          ])
          setIsListening(false)
        }
      })
  }

  async function fetchPromptWithRetry() {
    try {
      const namespaceResponse = await fetchActivePrompt(namespaceKey)
      setSelectedPrompt(namespaceResponse.data.response.data[0])
    } catch (e) {
      console.log(e)
      if (retryCount < maxRetries) {
        retryCount++
        console.log(`Retry attempt ${retryCount}`)
        await fetchPromptWithRetry()
      } else {
        console.log("Maximum number of retries reached.")
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchPromptWithRetry()
    }
    fetchData()
  }, [])

  useEffect(() => {
    const messagesRef = ref(database, `chat-sessions/${sessionId}`)
    const handleMessageUpdate = data => {
      if (data && data?.content) {
        const { content, currentContentId } = data

        setIsListening(false)
        setIsTyping(true)

        // Firebase Stream Message.
        const messagesList = Object.keys(content)
          .map(key => {
            return content[key]?.content
          })
          .filter(Boolean)
          .join("")

        setTranscriptHistory(prevTranscriptHistory => {
          // Start by adding the assistant response if it's not already there
          if (
            prevTranscriptHistory[prevTranscriptHistory.length - 1]?.role !==
              "assistant" &&
            isTyping
          ) {
            return [
              ...prevTranscriptHistory,
              {
                role: "assistant",
                triggers: [],
                content: messagesList,
              },
            ]
            // If the last message is from the assistant and we are in streaming mode, update it as data comes in
          } else if (
            prevTranscriptHistory[prevTranscriptHistory.length - 1]?.role ===
              "assistant" &&
            isTyping
          ) {
            const newTranscriptHistory = [...prevTranscriptHistory]
            newTranscriptHistory[prevTranscriptHistory.length - 1].content =
              messagesList

            // Init triggers if not already defined.
            // contactFormBtn
            // costCalculatorBtn
            // saveTranscriptBtn
            // ebookDownloadBtn
            if (
              currentContentId !== undefined &&
              currentContentId.status === "generated"
            ) {
              newTranscriptHistory[prevTranscriptHistory.length - 1].triggers =
                getTriggers(selectedPrompt, newTranscriptHistory)
            }

            return newTranscriptHistory
          }

          return prevTranscriptHistory
        })
      }
    }

    const handleValueChange = snapshot => {
      const data = snapshot.val()
      handleMessageUpdate(data)
    }

    onValue(messagesRef, handleValueChange)

    return () => {
      off(messagesRef, "value", handleValueChange)
    }
  }, [isTyping, sessionId])

  function clearChat() {
    setTranscriptHistory([])
    setMetaData([])
    setReferences([])
    setSessionId(uuidv4())
  }

  const getTriggers = (prompt, transcripts) => {
    let triggers = {}
    if (prompt?.namespaceData?.triggers.length) {
      prompt?.namespaceData?.triggers.map(trigger => {
        const fnName = getDynamicFunction(trigger.workflow)
        if (fnName !== null) {
          triggers[trigger.workflow] = fnName(transcripts, trigger)
        }
      })
    }
    return triggers
  }

  const getDynamicFunction = functionName => {
    switch (functionName) {
      case "saveTranscriptBtn":
        return saveTranscriptBtn
      case "contactFormBtn":
        return contactFormBtn
      case "costCalculatorBtn":
        return costCalculatorBtn
      case "ebookDownloadBtn":
        return ebookDownloadBtn
      default:
        return null
    }
  }

  const saveTranscriptBtn = (transcripts, trigger) => {
    trigger = {
      ...trigger,
      show: false,
      trigged: false,
    }

    if (transcripts.length >= trigger.data.count) {
      trigger["show"] = true
    }

    return trigger
  }

  const contactFormBtn = (transcripts, trigger) => {
    trigger = {
      ...trigger,
      show: false,
      trigged: false,
    }

    const matches = trigger.data.words.find(phrase => {
      return transcripts[transcripts.length - 1].content
        ?.toLowerCase()
        .includes(" " + phrase + " ")
    })

    if (matches) {
      trigger["show"] = true
    }

    return trigger
  }

  const costCalculatorBtn = (transcripts, trigger) => {
    trigger = {
      ...trigger,
      show: false,
      trigged: false,
    }

    const matches = trigger.data.words.find(phrase => {
      return transcripts[transcripts.length - 1].content
        ?.toLowerCase()
        .includes(" " + phrase + " ")
    })

    if (matches) {
      trigger["show"] = true
    }

    return trigger
  }

  const ebookDownloadBtn = (transcripts, trigger) => {
    trigger = {
      ...trigger,
      show: true,
      trigged: false,
    }

    return trigger
  }

  /*************************************************/
  /* FEEDBACK SUBMISSIONS                          */
  /*************************************************/
  async function handleFeedback() {
    setSaving(true)

    const feedback = {
      positive: positiveFeedback,
      content: feedbackContent,
      user: currentUser?._profile?.data?.email || "anonymous",
      date: new Date().toISOString(),
      transcript: transcriptHistory,
      metaData: metaData,
    }

    await feedbackCollection.insertOne(feedback)

    setFeedbackContent({
      response: "",
      reasons: [],
      message: "",
    })
    setSaving(false)

    return
  }

  const fetchActivePrompt = async namespace => {
    return await axios.get(
      `${process.env.GATSBY_API_BASE_URL}/prompts?isSelected=true&namespace=${namespace}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  }

  return (
    <ChatContext.Provider
      value={{
        sessionId,
        namespace,
        scrollBottom,
        selectedPrompt,
        clearChat,
        feedbackContent,
        focused,
        gpt4,
        handleFeedback,
        handleSubmit,
        isListening,
        metaData,
        positiveFeedback,
        query,
        references,
        saving,
        setFeedbackContent,
        setFocused,
        setGpt4,
        setIsListening,
        setMetaData,
        setPositiveFeedback,
        setScrollBottom,
        setQuery,
        setReferences,
        setSaving,
        setTranscriptHistory,
        transcriptHistory,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

export function useChat() {
  const context = useContext(ChatContext)
  if (context === undefined) {
    throw new Error("useChat must be used within a ChatProvider")
  }
  return context
}

interface ChatValues {
  namespace: any
  selectedPrompt: any
  scrollBottom: any
  sessionId: any
  transcriptHistory: any[]
  query: string
  isListening: boolean
  focused: boolean
  metaData: any
  gpt4: boolean
  references: any[]
  positiveFeedback: boolean
  saving: boolean
  feedbackContent: any
  handleFeedback: () => void
  setTranscriptHistory: React.Dispatch<React.SetStateAction<any[]>>
  setQuery: React.Dispatch<React.SetStateAction<string>>
  setIsListening: React.Dispatch<React.SetStateAction<boolean>>
  setFocused: React.Dispatch<React.SetStateAction<boolean>>
  setMetaData: React.Dispatch<React.SetStateAction<any>>
  setGpt4: React.Dispatch<React.SetStateAction<boolean>>
  setReferences: React.Dispatch<React.SetStateAction<any[]>>
  setPositiveFeedback: React.Dispatch<React.SetStateAction<boolean>>
  setScrollBottom: React.Dispatch<React.SetStateAction<boolean>>
  setSaving: React.Dispatch<React.SetStateAction<boolean>>
  setFeedbackContent: React.Dispatch<React.SetStateAction<any>>
  handleSubmit: (overrideQuery?) => void
  clearChat: () => void
}
