/*** DEPENDENCIES ***/
import React, { useState } from "react"
import "../assets/MyFontsWebfontsKit.css"
import Main from "gatsby-theme-wild-child/src/components/Main"
import ContextProvider from "../context/ContextProvider"
import { ChatProvider } from "./chat/ChatProvider"
import Nav from "./nav"
import { RealmAppProvider } from "../context/RealmApp"
import * as Sentry from "@sentry/react"

Sentry.init({
  dsn: "https://b02b3e36988c4ff39358815d8adedfda@o4505402427310080.ingest.sentry.io/4505402429014016",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

export interface LayoutProps {
  children?: React.ReactNode
}

export const MenuContext = React.createContext({}) as React.Context<any>

function Layout({ children }: LayoutProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false) // Menu state
  const [navPosition, setNavPosition] = useState("") // Nav position

  return (
    <RealmAppProvider>
      <ContextProvider>
        <ChatProvider>
          <MenuContext.Provider
            value={{
              isMenuOpen,
              setIsMenuOpen,
              navPosition,
              setNavPosition,
            }}
          >
            {/* <Nav /> */}
            <Main>{children}</Main>
          </MenuContext.Provider>
        </ChatProvider>
      </ContextProvider>
    </RealmAppProvider>
  )
}

export default Layout
