import React from "react"

import {
  Box,
  Heading,
  Link,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react"
import Section from "gatsby-theme-wild-child/src/components/Section"

const linkListing = []

function DisclaimerCorporateTax() {
  return (
    <Section mt={8} minH="100vh">
      <SimpleGrid columns={2} spacing={10} mb={10}>
        <Box display={"flex"} alignItems={"center"}>
          <Heading fontSize="2xl" color="red.full" p={0}>
            Disclaimer
          </Heading>
        </Box>
      </SimpleGrid>
      <VStack spacing={6} maxW="1200px" w="full" mx={"auto"}>
        <Text fontSize="lg" color="gray.700" mb={2}>
          ChatVZ is an automated tool designed to provide general information from various credible sources, including government websites, free zone websites, and Virtuzone's website. The information provided is intended as an initial resource for users and not as personalized advice.
        </Text>
        <Text fontSize="lg" color="gray.700" mb={2}>
        As laws and regulations can frequently change, often without prior notice, we strive to ensure that the information provided by ChatVZ is accurate and current. However, there may be instances where some details may not reflect the most recent changes in law or regulation. The interpretation and application of such information can also vary across different jurisdictions.
        </Text>
        <Text fontSize="lg" color="gray.700" mb={2}>
        By using ChatVZ, you acknowledge that any decisions made based on the information provided are solely at your own discretion and risk. You agree that ChatVZ and Virtuzone PJSC bear no responsibility for any consequences arising from such decisions.
        </Text>
        <Text fontSize="lg" color="gray.700" mb={2}>
        For accurate and personalized advice, we strongly encourage you to consult with a professional or directly refer to the relevant source of information. These resources can provide advice tailored to your specific circumstances, helping you to navigate the complexities of the law and regulations effectively.
        </Text>
        <Text fontSize="lg" color="gray.700" mb={2}>
        We encourage all users to refer to our sources for further reading and more detailed information.
        </Text>
        {linkListing.map((data, index) => (
          <Text
            fontSize="lg"
            color="gray.700"
            w="full"
            key={index}
            cursor="pointer"
          >
            <Link
              href={data.link}
              color="gray.700"
              textDecoration="underline"
              isExternal={true}
              fontSize="lg"
              fontWeight="500"
            >
              {data.title}
            </Link>
          </Text>
        ))}
      </VStack>
    </Section>
  )
}

export default DisclaimerCorporateTax
