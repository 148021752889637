import React from "react"

import {
  Box,
  Heading,
  Link,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react"
import Section from "gatsby-theme-wild-child/src/components/Section"

const linkListing = [
  {
    title: "https://mof.gov.ae/corporate-tax-faq/",
    link: "https://mof.gov.ae/corporate-tax-faq/",
  },
  {
    title:
      "https://mof.gov.ae/wp-content/uploads/2023/05/FINAL-CT-Guide-English-12.5.23.pdf",
    link: "https://mof.gov.ae/wp-content/uploads/2023/05/FINAL-CT-Guide-English-12.5.23.pdf",
  },
  {
    title: "https://tax.gov.ae/en/taxes/corporate.tax.aspx",
    link: "https://tax.gov.ae/en/taxes/corporate.tax.aspx",
  },
  {
    title: "https://tax.gov.ae/en/faq.aspx",
    link: "https://tax.gov.ae/en/faq.aspx",
  },
]

function DisclaimerBusinessSetup() {
  return (
    <Section mt={8} minH="100vh">
      <SimpleGrid columns={2} spacing={10} mb={10}>
        <Box display={"flex"} alignItems={"center"}>
          <Heading fontSize="2xl" color="red.full" p={0}>
            Disclaimer
          </Heading>
        </Box>
      </SimpleGrid>
      <VStack spacing={6} maxW="1200px" w="full" mx={"auto"}>
        <Text fontSize="lg" color="gray.700" mb={2}>
          TaxGPT is an automated tool designed to offer general information on
          tax and financial matters based on information published by the UAE
          Ministry of Finance and the Federal Tax Authority - the two official
          sources of information about corporate tax. The information provided
          is derived from government reference websites listed in the sources
          section below. However, this tool does not provide personalized advice
          and should not be relied upon as such. Instead, it is intended to
          serve as an initial resource for users seeking to understand certain
          tax and financial concepts.
        </Text>
        <Text fontSize="lg" color="gray.700" mb={2}>
          Tax laws and regulations are inherently complex and can frequently
          change, often without prior notice. As such, while we strive to ensure
          that the information provided by TaxGPT is accurate and current, there
          may be instances where some details may not reflect the most recent
          changes in the law. In addition, the interpretation and application of
          tax laws can vary significantly across different jurisdictions, making
          it challenging to provide universally applicable information.
        </Text>
        <Text fontSize="lg" color="gray.700" mb={2}>
          By using TaxGPT, you acknowledge that any decisions made based on the
          information provided are solely at your own discretion and risk. You
          also agree that TaxGPT and its creators bear no responsibility for any
          consequences arising from such decisions.
        </Text>
        <Text fontSize="lg" color="gray.700" mb={2}>
          For accurate, comprehensive, and personalized advice, we strongly
          encourage you to consult with a qualified tax professional or directly
          refer to the relevant government website. These experts can provide
          advice tailored to your specific circumstances, helping you navigate
          the complexities of tax laws and regulations effectively and
          efficiently.
        </Text>
        <Text fontSize="lg" color="gray.700" mb={2}>
          For a list of the government resources that TaxGPT uses to compile its
          information, see our sources list below. We encourage all users to
          refer to these resources for further reading and more detailed
          information.
        </Text>
        {linkListing.map((data, index) => (
          <Text
            fontSize="lg"
            color="gray.700"
            w="full"
            key={index}
            cursor="pointer"
          >
            <Link
              href={data.link}
              color="gray.700"
              textDecoration="underline"
              isExternal={true}
              fontSize="lg"
              fontWeight="500"
            >
              {data.title}
            </Link>
          </Text>
        ))}
      </VStack>
    </Section>
  )
}

export default DisclaimerBusinessSetup
